<template>
<div class="trip-container" @click="$emit('click')">
    <div class="sx" >
        <img src="../assets/icons/trip-xl.png" />
        <div class="content">
            <p class="bold">{{ trip.name }}</p>
            <p class="light" v-if="hasNoDates(trip)">-</p>
            <p class="light" v-else>{{ (new Date(trip.start_date)).toLocaleDateString() }} - {{ (new Date(trip.end_date)).toLocaleDateString() }}</p>
        </div>
    </div>
    <div v-if="countDown" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <p clsas="bold" v-if="hasNoDates(trip)">0</p>
        <p class="bold" v-else>-{{ getDaysUntil(new Date(trip.start_date)) }}</p>
        <p class="light">{{ $t('common.days') }}</p>
    </div>
    <vs-tooltip v-else v-model="tripCtxMenu" left shadow interactivity>
        <vs-button @click="(e) => {e.stopPropagation(); tripCtxMenu = true; }" dark style="height: 40px;"><img src="../assets/icons/more.png" style="width: 24px;" /></vs-button>

        <template #tooltip>
            <TripCtxMenu :callback="tripCtxMenuCallback" />

        </template>

    </vs-tooltip>

</div>
</template>

<script>
import TripCtxMenu from './ctx-menu/TripCtxMenu.vue';

export default {
    name: "trip",
    props: {
        countDown: {
            type: Boolean,
            default: false
        },
        trip: Object
    },
    methods: {
        hasNoDates(trip){
            var s = new Date(trip.start_date);
            var e = new Date(trip.end_date);
            if(s.getFullYear() <= 1970 || e.getFullYear() <= 1970){
                return true;
            }
            return false;
        },
        getDaysUntil(date) {
            const today = new Date();
            const tripDate = new Date(date);
            const diffTime = Math.abs(tripDate - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if(diffDays <= 0){
                return 0;
            }
            return diffDays;
        },
        tripCtxMenuCallback(e) {
            if (e == 'delete') {
                this.$emit('delete', this.trip)
            }
            this.tripCtxMenu = false;
            var el = document.getElementsByClassName('vs-tooltip');
            if (el != null && el.length > 0) {
                el[0].remove();
            }
        }
    },

    data() {
        return {
            tripCtxMenu: false
        }
    },
    components: {
        TripCtxMenu
    }
}
</script>

<style scoped>
.trip-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    padding: 20px 15px;
    cursor: pointer;
}

.sx {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

p {
    margin: 0;
}
</style>
