<template>
<div class="register-container">
    <TourialLogo />
    <div style="width: 100%;">
        <h3 class="bold">{{ $t('register.title') }}</h3>
    </div>
    


    <form style="margin-top: 10px;">
        <TransitionGroup name="slide-down" mode="out-in">

        <vs-input block key="name" :placeholder="$t('common.name')" v-model="name" v-on:keyup.enter="registerEvent" v-if="signupMode"/>
        <vs-input block key="surname" :placeholder="$t('common.surname')" v-model="surname" v-on:keyup.enter="registerEvent" v-if="signupMode"/>
        <vs-input key="email" block :placeholder="$t('common.email')" v-model="email" type="email" v-on:keyup.enter="registerEvent" />
        <vs-input key="password" block type="password" :placeholder="$t('common.password')" v-model="password" v-on:keyup.enter="registerEvent" v-if="signinMode || signupMode"/>
        <vs-input key="rpassword" block type="password" :placeholder="$t('common.confirmPassword')" v-model="rpassword" v-on:keyup.enter="registerEvent" v-if="signupMode"/>
        <div v-if="signupMode" key="agreements">
            <vs-checkbox v-model="acceptAll">
                {{ $t('register.acceptAll') }}
            </vs-checkbox>

            <vs-checkbox v-model="tosAgreement">
                <a  @click="showTos">{{ $t('register.acceptTermsOfService') }}</a>
            </vs-checkbox>
            <vs-checkbox v-model="privacyAgreement">
                <a  @click="showPrivacyPolicy">{{ $t('register.acceptPrivacyPolicy') }}</a>
            </vs-checkbox>
            <vs-checkbox v-model="want_newsletter">
                {{ $t('profile.keepMeUpdated') }}
            </vs-checkbox>
        </div>
        </TransitionGroup>
    </form>

    <vs-button v-if="signupMode" size="large" block class="bold animate" @click="registerEvent" :loading="loading">{{ $t('register.signUp').toUpperCase() }}</vs-button>
    <vs-button v-else-if="signinMode" size="large" block class="bold animate" @click="loginEvent" :loading="loading">{{ $t('login.signIn').toUpperCase() }}</vs-button>
    <vs-button v-else size="large" block class="bold animate" @click="checkUser" :loading="loading">{{ $t('common.next').toUpperCase() }}</vs-button>
    

    <p class="center" v-if="signupMode">
        {{ $t('register.alreadyHaveAnAccount')  }}
        <a @click="signinMode = false; signupMode = false; email = '';" to="/explore/login">{{ $t('register.signIn') }}</a>
    </p>

    <p class="center">
        {{ $t('login.signInWith') }}
    </p>

    <vs-button transparent size="large" :loading="googleLoading" @click="signInWithGoogle"><img src="../../assets/icons/google.png" /></vs-button>
    <DialogWrapper v-if="component != null" :component="component" :is-mobile="$parent.$parent.isMobile" @close="() => {component = null}" :blur="false" />
    <Footer/>

</div>
</template>

<script>

import TourialLogo from '../TourialLogo.vue'
import {
    apiCall,
    hash
} from '../../utils/ApiMiddleware';
import DialogWrapper from '../DialogWrapper.vue';
import {
    GlobalEventEmitter
} from '../../utils/GlobalEventEmitter';
import TrialDialog from '../dialogs/TrialDialog.vue';
import Footer from '../Footer.vue';
import {
    baseUri
} from '../../utils/ApiMiddleware';
export default {
    name: "register",
    props: {},
    components: {
        TourialLogo,
        DialogWrapper,
        Footer,
    },

    data() {
        return {
            name: '',
            surname: '',
            email: '',
            password: '',
            rpassword: '',
            want_newsletter: false,
            tosAgreement: false,
            privacyAgreement: false,
            language_id: 0,
            loading: false,
            component: null,
            googleLoading: false,
            acceptAll: false,

            signupMode: false,
            signinMode: false,

        }
    },

    methods: {
        async checkUser(){
            // we try to login, if ti gives 404 as status code it doesnt exist, otherwise it exists 
            const response = await apiCall('POST', '/users/login', {
                email: this.email,
                password: hash(''),
            }, true);

            if (response.status == 404) {
                this.signupMode = true;
                this.signinMode = false;
            } else {
                this.signupMode = false;
                this.signinMode = true;
            }
        },

        async signInWithGoogle() {
            this.googleLoading = true;
            setTimeout(() => {
                this.googleLoading = false;
                window.location.href = baseUri + '/auth/external?provider=google&is_app=' + JSON.stringify(this.$IS_APP) + (this.$IS_APP ? '&duration=15552000000000000' : '');
            }, 1000);
        },

        showTos(e) {
            e.preventDefault();
            this.$router.push('/tos');
        },

        showPrivacyPolicy(e) {
            e.preventDefault();
            this.$router.push('/privacy-policy');
        },

        async loginEvent() {
            this.loading = true;
            await this.login();
            this.loading = false;
        },

        async login() {
            // use apiCall to call the login api
            const response = await apiCall('POST', '/users/login', {
                email: this.email,
                password: hash(this.password),
                duration: this.$IS_APP ? 15552000000000000 : undefined, // it's in nanosecond, 180 days
            });
            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if (response.status == 200) {
                /*this.$vs.notification({
                    title: this.$t('login.title'),
                    text: this.$t('login.messages.success'),
                    color: 'success',
                    position: 'top-right'
                });*/
                GlobalEventEmitter.$emit('loadUserMeta');
                this.$router.push('/explore');
            } else if (response.status != 0) { // 0 is an already handled error, no needs to show another error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('login.messages.error'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async registerEvent() {
            this.loading = true;
            await this.register();
            this.loading = false;
        },
        async register() {

            // language setting
            var lang = navigator.language || navigator.userLanguage;
            if (lang != null && lang != '') {
                lang = lang.split('-')[0].toUpperCase();
            }

            const languagesResponse = await apiCall('GET', '/users/languages');
            if (languagesResponse.status != 200) {
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.cannotRegister')
                })
                return;
            }

            var languageMatch = languagesResponse.data.language.find(x => x.name == lang);
            if (languageMatch != null) {
                this.language_id = languageMatch.id;
            } else {
                this.language_id = languagesResponse.data.language.find(x => x.name == 'EN');
            }

            if (this.password != this.rpassword) {
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.passwordMismatch')
                })
                return;
            }

            // if tos agreement are not acecepted, show an error message
            if (!this.tosAgreement) {
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.mustAcceptTerms')
                })
                return;
            }

            // if privacy agreement are not acecepted, show an error message
            if (!this.privacyAgreement) {
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.mustAcceptPrivacy')
                })
                return;
            }

            // if password is not enought strong show an error message
            if (this.password.length < 8) {
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.passwordTooShort')
                })
                return;
            }

            var link_id = this.$cookie.get('TOURIAL-C');

            const response = await apiCall('POST', '/users/register', {
                name: this.name,
                surname: this.surname,
                email: this.email,
                password: hash(this.password),
                language_id: this.language_id,
                want_newsletter: this.want_newsletter,
                link_id
            });

            // if status code is ok, show a success message
            if (response.status == 200) {
                this.$vs.notification({
                    color: 'success',
                    position: 'top-right',
                    title: this.$t('common.messages.success'),
                    text: this.$t('register.messages.registered')
                })

                // login
                // use apiCall to call the login api
                const loginResponse = await apiCall('POST', '/users/login', {
                    email: this.email,
                    password: hash(this.password),
                    duration: this.$IS_APP ? 15552000000000000 : undefined, // it's in nanosecond, 180 days
                });
                // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
                if (loginResponse.status == 200) {
                    /*this.$vs.notification({
                        title: this.$t('login.title'),
                        text: this.$t('login.messages.success'),
                        color: 'success',
                        position: 'top-right'
                    });*/
                    GlobalEventEmitter.$emit('loadUserMeta');
                    GlobalEventEmitter.$emit('showDialog', TrialDialog, () => {
                    })
                    this.$router.push('/explore');
                } else if (loginResponse.status != 0) { // 0 is an already handled error, no needs to show another error message
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('login.messages.error'),
                        color: 'danger',
                        position: 'top-right'
                    });
                }
            } else if (response.status == 409) {
                // show an error message saying that is already registered
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.emailAddressAlreadyUsed')
                })
            } else if (response.status != 0) { // 0 is an already handled error, no needs to show another error message
                // if status code is not ok, show an error message
                this.$vs.notification({
                    color: 'danger',
                    position: 'top-right',
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('register.messages.cannotRegister')
                })
            }

        },
    },

    watch: {
        acceptAll(n){
            if(n){
                this.tosAgreement = true;
                this.privacyAgreement = true;
                this.want_newsletter = true;
            }
        }
    }
}
</script>

<style scoped>
.register-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;

}

/* DESKTOP */
@media (min-width: 1024px) {
    .register-container {
        width: 350px;
    }
}

/* MOBILE */
@media (max-width: 600px) {
    .register-container {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        height: unset !important;
        width: 100% !important;
    }
}
</style>
