import Vue from 'vue'
import App from './App.vue'
import { App as NApp } from '@capacitor/app';
import { Haptics, NotificationType } from '@capacitor/haptics';
import i18n from './utils/i18n.js'
import { storeTokenInSecureStorage, handleGoogleStatus } from './utils/ApiMiddleware.js';
import { GlobalEventEmitter } from './utils/GlobalEventEmitter.js';
Vue.config.productionTip = false
Vue.prototype.$TARGETS = {};
Vue.prototype.$TRIAL_DAYS = 14;
Vue.prototype.$IS_APP = window.navigator.userAgent.includes('TourialApp');
Vue.prototype.$STRIPE_PK = 'pk_live_51N2adtANRLRb2OclbphcXmypySTaQjwPZTJF1k4HxYN4on8n3GVvd4GZWDyyQmKBJLEgh5FKVLpI7t3SCbwGsBXb00uVQty7mv';

if(!Vue.prototype.$IS_APP && window.location.hostname != 'www.tourial.it' && window.location.hostname != 'tourial.it'){
  Vue.prototype.$STRIPE_PK = 'pk_test_51N2adtANRLRb2OcljbTnbwXPmwXdLJn2hCex3YqEsECrDGmWZiWBgxhRe6iA79wEecmREtcfuchveTUU6jVbcAM300OH8TVBSC';
}

// OVERRIDE FOR TESTING
//Vue.prototype.$STRIPE_PK = 'pk_test_51N2adtANRLRb2OcljbTnbwXPmwXdLJn2hCex3YqEsECrDGmWZiWBgxhRe6iA79wEecmREtcfuchveTUU6jVbcAM300OH8TVBSC';

import * as Sentry from "@sentry/vue";

import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

import 'lightgallery.js'
import 'lightgallery.js/dist/css/lightgallery.css'
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
  colors: {
    primary:'#EF7E18',
    dark:'rgba(0, 0, 0, 0.1)'
  }
})

// WRAP $vs.notification to add haptic feedback on mobile
const originalNotify = Vue.prototype.$vs.notification;
Vue.prototype.$vs.notification = function (options) {
  originalNotify(options);
  if (Vue.prototype.$IS_APP) {
    let type = NotificationType.Success;
    if (options.color == 'danger') {
      type = NotificationType.Error;
    }
    Haptics.notification({
      type,
    });
  }
};

import VueSlideoutPanel from 'vue2-slideout-panel';
Vue.use(VueSlideoutPanel);


import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import 'boxicons/css/boxicons.min.css'
const vRouter = new VueRouter({
  mode: 'history',
  routes: require('./routes.js').routes
})

Sentry.init({
  Vue,
  dsn: "https://3a0f76a384b9efbf406f1b4d41ff95e4@sentry.wised.it/3",
  environment: window.configuration.IS_TEST ? 'TEST' : 'PROD', // production, test
  integrations: [
    Sentry.browserTracingIntegration({ router: vRouter }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: window.configuration.IS_TEST ? [window.location.origin] : [], // Allow all network requests to be captured
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [window.location.origin],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

NApp.addListener('appUrlOpen', async (data) => {
  GlobalEventEmitter.$emit('appUrlOpen', data);

  if (data.url.includes('tourial://')) {
    // get query params
    let url = new URL(data.url);
    let params = url.searchParams;

    // get token
    let token = params.get('token');
    if(token){
      await storeTokenInSecureStorage(token);
      GlobalEventEmitter.$emit('loadUserMeta');
    }

    let status = params.get('status');
    if(status){
      handleGoogleStatus(status);
    }
  }
});
// global vue variable for navigation history
Vue.prototype.$NAVIGATION_HISTORY = new Array();
const DEFAULT_TITLE = 'Tourial';

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

vRouter.beforeEach((to, from, next) => {
   // if from name equals 'Viaggio' and to name equals 'Scheda del luogo', override to meta params 'menu' with 'Viaggi', otherwise set it to 'Esplora'
  if(to.name == 'Scheda del luogo'){
    if(from.name == 'Viaggio'){
      to.meta.menu = 'Viaggi';
    } else {
      to.meta.menu = 'Esplora';
    }
  }


   if(!hasQueryParams(to) && hasQueryParams(from)){
    if(from.query.status == 'null'){
      delete from.query.status;
    }

    if(from.query.check_subscription == 'null'){
      delete from.query.check_subscription;
    }

    if(from.query.payment_intent_client_secret == 'null'){
      delete from.query.payment_intent_client_secret;
    }
    next({ ...to, query: from.query });
  } else {
    next()
  }
})

vRouter.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.meta.title != undefined ? (DEFAULT_TITLE + ' | ' + to.meta.title) : DEFAULT_TITLE;
  });
  // detect navigation back
  if (Vue.prototype.$NAVIGATION_HISTORY.length > 0 && Vue.prototype.$NAVIGATION_HISTORY[Vue.prototype.$NAVIGATION_HISTORY.length - 1].fullPath == to.fullPath) {
    Vue.prototype.$NAVIGATION_HISTORY.pop();
  } else {
    Vue.prototype.$NAVIGATION_HISTORY.push(from);
  }

  if(to.meta.menu != from.meta.menu && !['HomePage', 'Preferiti'].includes(from.meta.menu)){
    // reset navigation history
    Vue.prototype.$NAVIGATION_HISTORY = new Array();
  }
});




import "@fontsource/montserrat"; // Defaults to weight 400.
import "@fontsource/montserrat/500.css"; // Weight 500.
import "@fontsource/montserrat/600.css"; // Weight 500.
import "@fontsource/montserrat/700.css"; // Weight 500.
import "@fontsource/montserrat/800.css"; // Weight 500.


import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '34325553753-dk8esvk9sda8bd2ev3m1a4721adccldl.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)



const VueCookie = require('vue-cookie');
Vue.use(VueCookie);


new Vue({
  render: h => h(App),
  router: vRouter,
  i18n
}).$mount('#app')

export const router = vRouter