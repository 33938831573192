<template>
<div class="notifications-container">
    <div class="top">
        <div class="header">
            <h2 style="margin: 0;">{{ $t('notifications.title') }}</h2>
            <p style="margin: 0;">{{ $t('notifications.caption') }}</p>
        </div>

    </div>
    <div v-if="notifications == null || notifications.length == 0" style="display: flex; flex-direction: column; gap: 20px; align-items: center;">
        <img src="../../assets/images/no_notifications.png" style="width: 50%;" />
        <h3>{{ $t('notifications.noNotifications') }}</h3>
    </div>
    <Notification v-for="n in notifications" :key="'notification-' + n.id" :notification="n" @dismissed="getNotifications" />

</div>
</template>

<script>
import Notification from '../Notification.vue';
import {
    apiCall
} from '../../utils/ApiMiddleware';
export default {
    name: "notifications",
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        Notification
    },
    data() {
        return {
            notifications: []
        }
    },
    mounted() {

        this.getNotifications()
    },
    methods: {
        async getNotifications() {
            this.notifications = [];
            if (this.user != null && (this.user.birthdate == '' || this.user.country == null || this.user.gender == '')) {
                this.notifications.push({
                    title: this.$t('notifications.completeProfile.title'),
                    text: this.$t('notifications.completeProfile.caption'),
                    call_to_action_type: 'ROUTE',
                    call_to_action_value: 'Modifica profilo',
                    dismissable: false
                });
            }
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/notifications', {only_unread: true});
            loading.close();
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (response.data.data != null) {
                    this.notifications = this.notifications.concat(response.data.data);
                }
            }

        },
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.notifications-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    height: calc(100% - 75px);

}

/* MOBILE */
@media (max-width: 600px) {
    .notifications-container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;
        padding-top: 20px;

    }

}
</style>
