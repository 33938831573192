<template>
<div class="editreview-container">
    <div class="top">

        <div class="fields">

            <div class="field" v-if="review.user">
                <p>Utente</p>
                <p>{{ review.user.name }} {{ review.user.surname }}</p>
            </div>

            <div class="field" v-if="review.poi">
                <p>POI</p>
                <p>{{ review.poi.localized != null && user !== null ? review.poi.localized.find(e => e.language_id == user.language.id).name : '-' }}</p>
            </div>

            <div class="field">
                <p>Stelle</p>
                <Stars :stars="review.rating" />
            </div>

            <div class="field" style="align-items: flex-start!important;">
                <p>Testo</p>
                <p style="white-space: pre-wrap;">{{ review.description }}</p>
            </div>


            <vs-row direction="column" style="gap: 20px; margin-top: 20px; width: 100%;" justify="center">
                <vs-card v-for="(url, index) in pictures" :key="index" style="width: 100%;">

                    <template #img>
                        <img :src="url" style="width: 100%;" />
                    </template>
                    
                </vs-card>
            </vs-row>

            <vs-button danger border size="large" :loading="deleting" @click="deleteReview">{{ $t('common.delete').toUpperCase() }}</vs-button>


        </div>
    </div>

</div>
</template>

<script>
import {
    s3Client
} from '../../utils/S3Client';
import {
    apiCall,
    baseUri,
} from '../../utils/ApiMiddleware';
import Stars from '../Stars.vue';
export default {
    name: "reviewEl",
    props: {
        review: {
            type: Object,
            required: true
        },

        user: {
            type: Object,
            required: true
        },

        deleted: {
            type: Function,
            required: true
        }
    },
    components: {
        Stars
    },
    data() {
        return {
            baseUri,
            deleting: false,
            pictures: []
        }
    },

    async mounted() {
        // Load pictures
        if (this.review.pictures && this.review.pictures.length > 0) {
            for (let i = 0; i < this.review.pictures.length; i++) {
                const picture = this.review.pictures[i];
                const urlResponse = await s3Client.getSignedDownloadURL({
                    name: picture
                });

                if(urlResponse && urlResponse.url) {
                    this.pictures.push(urlResponse.url);
                }
            }
        }
    },

    methods: {
        async deleteReview() {
            this.deleting = true;
            const result = await apiCall('DELETE', '/reviews/' + this.review.id);
            this.deleting = false;
            if (result && result.status === 200){
                this.$vs.notification({
                    title: 'Successo',
                    text: 'Recensione eliminata con successo',
                    color: 'success',
                    position: 'top-right'
                });
                this.deleted();
            } else if (result && result.status !== 0) {
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Errore durante l\'eliminazione della recensione',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
    },

    watch: {
        
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.editreview-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .editreview-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;

    }

}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}
</style><style>
.editreview-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.editreview-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.editreview-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}
</style>

<style >
.editreview-container .vs-card {
    max-width: unset!important;
}
</style>