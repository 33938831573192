<template>
<div class="mobilemenu-container" :style="{height: innerHeight+'px'}">
    <BreadCumb name="Indietro" :back="back" />
    <div class="content">
        <div class="user" v-if="user != null" @click="goToProfile">
            <vs-avatar circle size="100" >
                <img src="../assets/images/default-avatar.webp" v-show="noProfilePicture" />

                <img :src="pictureUrl" @error="pictureNotFound" v-if="!noProfilePicture">
            </vs-avatar>
            <div class="user-info">
                <h4 class="bold">{{ user != null ? user.name : '..' }} {{ user != null ? user.surname : '..' }}</h4>
                <p>{{ user != null ? user.email : '..' }}</p>
            </div>
        </div>
        <vs-button v-else style="width: 50%;" @click="$router.push('/explore/login')" primary size="large">{{ $t('login.signIn') }}</vs-button>

        <div class="buttons">
            
            <vs-button transparent @click="active = 'HomePage'" :class="{'active': active == 'HomePage'}" block v-if="!$IS_APP">
                <img class="active" src="../assets/icons/menu/home_active.png" />
                <img class="inactive" src="../assets/icons/menu/home.png" />
                {{ $t('common.homePage') }}
            </vs-button>
            <vs-button transparent @click="active = 'ContactUs'" :class="{'active': active == 'ContactUs'}" block>
                <img class="active" src="../assets/icons/menu/contact_active.png" />
                <img class="inactive" src="../assets/icons/menu/contact.png" />
                {{ $t('common.contactUs') }}
            </vs-button>
            <vs-button transparent @click="active = 'Blog'" :class="{'active': active == 'Blog'}" block>
                <img class="active" src="../assets/icons/menu/blog_active.png" />
                <img class="inactive" src="../assets/icons/menu/blog.png" />
                {{ $t('common.blog') }}
            </vs-button>
        </div>
    </div>
</div>
</template>

<script>
import {
    s3Client
} from '../utils/S3Client';
import BreadCumb from './BreadCumb.vue';
export default {
    name: 'mobileMenu',
    props: {
        component: Object,
        user: Object,
        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },
    components: {
        BreadCumb
    },

    data() {
        return {
            noProfilePicture: false,
            active: '',
            pictureUrl: null
        }
    },

    watch: {
        '$route'(n) {
            this.active = n.meta.menu;
        },
        active(n) {
            this.$router.push({
                name: n
            })
            this.back()
        },

        user() {
            this.loadProfilePicture();
        }
    },

    mounted(){
        this.loadProfilePicture();
    },

    methods: {

        async loadProfilePicture(){
            if(this.user && this.user.picture_id != null){
                const s3Result = await s3Client.getSignedDownloadURL({
                    name: this.user.picture_id,
                });

                if(s3Result && s3Result.url){
                    this.pictureUrl = s3Result.url;
                    return;
                }
            }

            this.pictureNotFound();
        },
        back() {
            this.$parent.mobileMenuVisible = false;
        },
        pictureNotFound() {
            this.noProfilePicture = true;
        },
        goToProfile(){
            if(this.$route.name != 'Profilo'){
                this.$router.push({name: 'Profilo'})
            } else {
                this.$emit('close')
            }
                
        }
    }
}
</script>

<style scoped>
.mobilemenu-container {
    width: 100%;
    z-index: 2300 !important;
    position: fixed;
    background: white;
}

.content {
    padding-top: 75px;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.user {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-left: 10px;
}

.user img {
    border-radius: 100px;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

h4,
p {
    margin: 0;
}
</style><style>
.mobilemenu-container .buttons .vs-button__content {
    justify-content: flex-start !important;
    gap: 10px;
    align-items: center;
}

.mobilemenu-container .vs-button__content img {
    width: 30px;
}

.mobilemenu-container .vs-button img.active {
    display: none;
}

.mobilemenu-container .vs-button.active img.active {
    display: block;
}

.mobilemenu-container .vs-button.active img.inactive {
    display: none !important;
}
</style>
