<template>
<div class="poi-container">

    <div class="swiper" v-if="images && images.length > 0">
        <div class="swiper-wrapper" id="lg-swipper">
            <a v-for="(image,i) in images" :key="'image-'+i" class="swiper-slide" :data-src="image.url" :data-sub-html="image.description">
                <img class="img-responsive" loading="lazy" :src="image.url" />
                <p style="margin: 0;">{{ image.description }}</p>
            </a>
        </div>

        <!--navigation-->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>

    </div>
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; gap: 10px;">
        <vs-row direction="row" justify="flex-start" align="center" style="max-width: calc(100% - 70px);">
            <img v-if="poi != null" :src="$TARGETS[poi.target_id]" style="width: auto; height: 64px;" />

            <div style="display: flex; justify-content: center; flex-direction: column; gap: 5px; max-width: calc(100% - 70px);">
                <h3 style="margin: 0;">{{ poi != null ? poi.name : 'Loading..' }}</h3>
                <div style="display: flex; justify-content: flex-start; align-items: center; gap: 10px;">
                    <Stars :stars="poi != null ? Math.floor(poi.average_rating) : 0" />
                    <p style="margin: 0;" class="light">{{ poi != null ? poi.number_of_reviews : 0 }} {{ $t('common.reviews') }}</p>
                </div>
            </div>
        </vs-row>

        <vs-tooltip v-model="upgradeTooltip" not-arrow not-hover :bottom="isMobile" :left="!isMobile" border color="#EF7E18" style="padding: 0; margin: 0;" v-if="user != null">
            <vs-button v-if="poi != null && poi.my_place" @click="togglePoiMyPlaces" dark color="#FFE6D0" style="width: 50px;"><img src="../../assets/icons/pin.png" style="width:24px;" /></vs-button>
            <vs-button v-else @click="togglePoiMyPlaces" dark style="width: 50px;"><img src="../../assets/icons/pin_grey.png" style="width:24px;" /></vs-button>

            <template #tooltip>
                <div class="content-tooltip">
                    <h4 class="center">
                        {{ $t('common.thisFeatureIsOnlyAvailableInPremiumVersion') }}
                    </h4>
                    <footer>
                        <vs-button block size="large" @click="upgrade" :loading="upgradeLoading">
                            {{ $t('profile.subscription.upgrade') }}
                        </vs-button>
                    </footer>
                </div>
            </template>
        </vs-tooltip>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('common.target') }}</p>
        <p>{{ poi != null ? poi.target_name : 'Loading..' }}</p>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('poi.yearOfConstruction') }}</p>
        <p>{{ poi != null ? poi.year_built : '----' }}</p>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('poi.author') }}</p>
        <p>{{ poi != null ? (poi.author == '' ? '-' : poi.author) : 'Loading..' }}</p>
    </div>

    <div class="poi-section">
        <p class="bold">{{ $t('poi.description') }}</p>
        <p>{{ poi != null ? (fullDescription ? poi.description : poi.description.substr(0,200)) : 'Loading..'}}</p>
        <a style="cursor: pointer;" @click="fullDescription = true" v-if="!fullDescription && poi != null && poi.description.length > 200">{{ $t('common.showMore') }}</a>
        <a style="cursor: pointer;" @click="fullDescription = false" v-if="fullDescription">{{ $t('common.showLess') }}</a>
    </div>

    <div style="display: flex; justify-content: space-between; width: 100%; gap: 15px;">
        <vs-button dark block class="bold" @click="addToTrip"><img src="../../assets/icons/trip.png" style="width: 24px;" @click="addToTrip" />{{ $t('poi.addToTrip').toUpperCase() }}</vs-button>
        <vs-button v-if="poi != null && poi.liked" @click="togglePoiLike" dark color="#FFE6D0" style="width: 50px;"><img src="../../assets/icons/favourite.png" style="width:24px;" /></vs-button>
        <vs-button v-else @click="togglePoiLike" dark style="width: 50px;"><img src="../../assets/icons/favourite_grey.png" style="width:24px;" /></vs-button>
    </div>

    <div style="display: flex; justify-content: flex-start; width: 100%; align-items: center; gap: 5px;">
        <img src="../../assets/icons/location.png" style="width: 24px; height: 24px;" />
        <p class="bold">{{ poi != null ? poi.street : 'Loading..' }}</p>
    </div>

    <div class="map-gradient">
        <div class="gradient"></div>
        <OpenStreetMap style="width: 100%; height: 120px; " v-if="poi != null" :controls-enabled="false" :center="[poi.latitude, poi.longitude]" :pois="[poi]" />
    </div>

    <div style="display: flex; justify-content: space-between; width: 100%; gap: 15px;">
        <vs-button dark block class="bold" @click="getDirections"><img src="../../assets/icons/direction.png" style="width: 18px;" />{{ $t('poi.getDirections').toUpperCase() }}</vs-button>
        <vs-button dark style="width: 50px;" :alt="poi != null ? poi.website : ''" @click="openWebsite" :disabled="poi == null || poi.website == ''"><img src="../../assets/icons/www.png" style="width: 30px;" /></vs-button>
    </div>

    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <p class="bold">{{ $t('common.reviews') }}</p>
        <p class="bold" style="display: flex; align-items: center; gap: 5px; cursor: pointer;" @click="$router.push({name: 'Recensioni'})">{{ $t('common.showAll') }} <img src="../../assets/icons/right.png" style="width: 16px;" /></p>
    </div>

    <Reviews :poi="poi" :user="user" v-model="reviews" /><!-- reviews is valued by reviews component once get the reviews, we need this to show the pictures -->

</div>
</template>

<script>
import { Browser } from '@capacitor/browser';

import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';

import {
    s3Client
} from '@/utils/S3Client';

import Stars from '../Stars.vue'
import OpenStreetMap from '../OpenStreetMap.vue';
import Reviews from '../Reviews.vue';
import AddToTripDialog from '../dialogs/AddToTripDialog.vue';
import TripDateDialog from '../dialogs/TripDateDialog.vue';
import NewTripDialog from '../dialogs/NewTripDialog.vue';
import {
    GlobalEventEmitter
} from '@/utils/GlobalEventEmitter'
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

// import Swiper styles
export default {
    name: "poi",
    props: {
        user: Object,

        isMobile: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Stars,
        OpenStreetMap,
        Reviews,
    },

    data() {
        return {
            poi: null,
            fullDescription: false,
            selectedTrip: null,
            baseUri,
            upgradeTooltip: false,
            upgradeLoading: false,

            googleImages: [], // obtained from S3
            images: [], // images to show in the gallery
            reviews: [],
            loadingImages: true,
        }
    },

    async mounted() {
        await this.getPoi(this.$route.params.id)
    },

    watch: {
        async reviews(n) { // reviews are always loaded after poi details, so we are sure that poi pictures will be already loaded
            let tempImages = this.googleImages;

            // if reviews are loaded, and there are no pictures in poi, show them as pictures
            if (n != null) {
                let reviewImages = await Promise.all(
                    n.filter(e => e.pictures != null && e.pictures.length > 0).map(async (review) => {
                        const pictureUrls = await Promise.all(
                            review.pictures.map(async (picture) => {
                                console.log('PICTURESSS',picture)
                                const response = await s3Client.getSignedDownloadURL({
                                    name: picture,
                                });
                                console.log(response)
                                if (response && response.url) {
                                    return {
                                        url: response.url,
                                        description: review.user.name + (review.user.surname ? ' ' + review.user.surname : ''),
                                        transition: 'kenburn'
                                    };
                                }
                                return null;
                            })
                        );
                        return pictureUrls.filter(p => p !== null); // Filter out null entries
                    })
                );

                reviewImages = reviewImages.flat();

                if (reviewImages && reviewImages.length > 0) {
                    tempImages = tempImages.concat(reviewImages);
                }
            }


            // show gallery if there is at least one image
            if (tempImages.length > 0) {
                this.images = tempImages;
                // at next tick, init the gallery, in this way we are sure DOM is updated
                this.$nextTick(() => {
                    this.initGallery();
                })
            }

            console.log(JSON.parse(JSON.stringify(this.images)))
        }
    },

    methods: {

        async initGallery() {
            console.log('init gallery')
            let $lgSwiper = document.getElementById('lg-swipper');
            const swiper = new Swiper('.swiper', {
                

                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },

                slidesPerView: 1,
                slidesPerGroup: 1,
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                spaceBetween: 50,
                loop: false,

                lazy: true,

                // Init lightGallery ince swiper is initilized
                on: {
                    init: function () {
                        const lg = window.lightGallery($lgSwiper);

                        // Before closing lightGallery, make sure swiper slide
                        // is aligned with the lightGallery active slide
                        $lgSwiper.addEventListener('lgBeforeClose', () => {
                            swiper.slideTo(lg.index, 0)
                        });
                    },
                }
            });

            // add events to navigation buttons
            document.querySelector('.swiper-button-next').addEventListener('click', () => {
                // if it's the last slide, go to the first one
                if (swiper.isEnd) {
                    swiper.slideTo(0);
                    return;
                }
                swiper.slideNext();
            });

            document.querySelector('.swiper-button-prev').addEventListener('click', () => {
                // if it's the first slide, go to the last one
                if (swiper.isBeginning) {
                    swiper.slideTo(swiper.slides.length - 1);
                    return;
                }
                swiper.slidePrev();
            });
        },

        async togglePoiLike() {
            if (this.poi == null) {
                return;
            }
            this.poi.liked = !this.poi.liked;

            // call the api to toggle the like
            const response = await apiCall('PUT', '/pois/' + this.poi.id + '/like', {
                liked: this.poi.liked
            })
            if (response.status == 200) {
                // success
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('common.poi') + ' ' + (this.poi.liked ? this.$t('common.addedTo').toLowerCase() : this.$t('common.removedFrom').toLowerCase()) + ' ' + this.$t('common.favourites').toLowerCase(),
                    color: 'success',
                    position: 'top-right'
                })
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotAddToFavourites'),
                    color: 'danger',
                    position: 'top-right'
                })
                this.poi.liked = !this.poi.liked;
            }
        },

        upgrade() {
            this.upgradeLoading = true;
            setTimeout(() => {
                this.upgradeLoading = false;
                this.upgradeTooltip = false;
                this.$router.push({
                    name: 'Sottoscrizione'
                })
            }, 1000);
        },

        async togglePoiMyPlaces() {
            if (this.poi == null) {
                return;
            }

            if (this.user.subscription == null && !this.user.is_partner && !this.user.is_admin) {
                this.upgradeTooltip = true;
                return;
            }

            this.poi.my_place = !this.poi.my_place;

            // call the api to toggle the like
            const response = await apiCall('PUT', '/pois/' + this.poi.id + '/myplaces', {
                added: this.poi.my_place
            })
            if (response.status == 200) {
                // success
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('common.poi') + ' ' + (this.poi.my_place ? this.$t('common.addedTo').toLowerCase() : this.$t('common.removedFrom').toLowerCase()) + ' ' + this.$t('explore.myPlaces').toLowerCase(),
                    color: 'success',
                    position: 'top-right'
                })
            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotAddToMyPlaces'),
                    color: 'danger',
                    position: 'top-right'
                })
                this.poi.my_place = !this.poi.my_place;
            }
        },

        async openWebsite() {
            if(this.$IS_APP){
                Browser.open({ url: this.poi.website, presentationStyle: 'popover' });
            } else {
                // open the url in new window
                window.open(this.poi.website, '_blank');
            }
            
        },

        getDirections() {
            window.open('https://www.google.com/maps/search/?api=1&query=' + this.poi.latitude + ',' + this.poi.longitude);
        },

        async getPoi(id) {
            const loading = this.$vs.loading();
            const response = await apiCall('GET', '/pois/' + id, {
                include_like_details: true,
                include_my_places_details: true
            });
            if (response.status == 200) {
                var poi = response.data;
                GlobalEventEmitter.$emit('setZoom', 15)
                GlobalEventEmitter.$emit('goToCoords', [poi.latitude, poi.longitude])

                if (poi.pictures && poi.pictures.length > 0) {
                    // load images from S3
                    let images = [];

                    for (let i = 0; i < poi.pictures.length; i++) {
                        const image = poi.pictures[i];
                        const s3Response = await s3Client.getSignedDownloadURL({
                            name: image.name,
                        })
                        if (s3Response && s3Response.url) {
                            images.push({
                                url: s3Response.url,
                                description: image.description,
                                transition: 'kenburn'
                            })
                        }
                    }

                    this.googleImages = images;
                }

                this.poi = poi;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotLoadPoi'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
            loading.close();
        },

        addToTrip() {
            GlobalEventEmitter.$emit('showDialog', AddToTripDialog, this.addToTripCallback)
        },

        async addToTripCallback(e) {
            if (e === null) {
                // creare nuovo trip
                GlobalEventEmitter.$emit('showDialog', NewTripDialog, this.newTripCallback) // chiedo il nome
            } else if (e !== false && e.id != null) {
                var tripDetails = await this.getTrip(e.id)
                if (tripDetails != null) {
                    this.selectedTrip = tripDetails;
                    GlobalEventEmitter.$emit('showDialog', TripDateDialog, this.addToTripDateChosenCallback, tripDetails) // chiedo la data in cui intende visitare il poi

                }
            }

        },

        async addToTripDateChosenCallback(e) {
            console.log(e)
            var vm = this;
            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                // to do, at the moment doesn't do anything
                if (this.selectedTrip.pois == null) {
                    this.selectedTrip.pois = []
                }
                this.selectedTrip.pois.push({
                    id: this.poi.id,
                    is_custom_poi: false,
                    start_date: e + 'T00:00:00Z',
                    end_date: e + 'T00:00:00Z',
                    position: this.selectedTrip.pois.length // to be sure it's the last one

                })
                console.log(this.selectedTrip)
                const loading = this.$vs.loading();
                if (await this.saveTrip()) {
                    this.$router.push({
                        path: '/explore/trips/' + vm.selectedTrip.id
                    });
                }
                loading.close();

            }
        },

        async newTripCallback(e) {

            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                if (await this.newTrip(e)) {
                    GlobalEventEmitter.$emit('showDialog', TripDateDialog, this.addToTripDateChosenCallback) // chiedo la data in cui intende visitare il poi
                }

            }
        },

        async getTrip(id) {

            // use apiCall to make a request to /trips
            const response = await apiCall('GET', '/journey/' + id);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                return response.data;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.unableToLoadTrip'),
                    color: 'danger',
                    position: 'top-right'
                });

            }
            return null;
        },

        async saveTrip() {

            const response = await apiCall('PUT', '/journeys/' + this.selectedTrip.id, this.selectedTrip);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show sucecss message
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('trips.messages.tripUpdated'),
                    color: 'success',
                    position: 'top-right'
                });
                return true;
            } else if (response.status == 409) {
                // show sucecss message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.poiAlreadyInTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('poi.messages.cannotAddToTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            return false;
        },

        async newTrip(name) {
            // use apiCall to make a request to /trips
            const response = await apiCall('POST', '/journeys', {
                name
            });
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show a success message
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('trips.messages.tripCreated'),
                    color: 'success',
                    position: 'top-right'
                });
                this.selectedTrip = {
                    id: response.data.id,
                    name
                }

                return true;

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.cannotCreateTrip'),
                    color: 'danger',
                    position: 'top-right'
                });

            }
            return false;

        },

    }
}
</script>

<style scoped>
.poi-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 15px;
    width: 100%;
    padding-bottom: 25px;
    overflow-x: hidden;
}

.map-gradient {
    height: 120px;
    width: 100%;
    min-height: 120px;
    position: relative;
}

.map-gradient div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.map-gradient .gradient {

    z-index: 2000;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.0001) 25%, rgba(255, 255, 255, 1) 100%);
}

p {
    margin: 0;
}

.poi-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.vs-button {
    margin: 0 !important;
}

/* MOBILE */
@media (max-width: 600px) {
    .poi-container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 90px !important;
        width: 100% !important;

    }

}

.content-tooltip {
    padding: 10px;
    box-sizing: border-box;
}

.content-tooltip button {
    margin: 0;
    margin-bottom: 5px;
}
</style><style>
.swiper-lg-wrap {
    height: 0;
    position: relative;
    max-width: 100%;
}

.swiper {
    width: 100%;
    height: 200px;
    position: relative;
}

.swiper .swiper-slide {
    height: auto;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide p {
    max-width: 90%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    top: 0;
    right: 5px;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}


.slider-two .swiper-pagination-bullet-active{
   background: red;
}

.swiper-button-next, .swiper-button-prev {
    top: 100px;
    color: white!important;
}

.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 18px;
}
</style>
