import Vue from 'vue'
import VueI18n  from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'it',
    fallbackLocale: 'en',
    messages: {
        it: require('../locales/it.json'),
        en: require('../locales/en.json'),
        es: require('../locales/es.json'),
        fr: require('../locales/fr.json'),
        zh: require('../locales/zh.json'),
        de: require('../locales/de.json'),
        pt: require('../locales/pt.json')
    }
})