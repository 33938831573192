<template>
    <div class="messageoverlay-container">

        <div class="message">
            <vs-button color="rgb(255,255,255)" @click="$emit('dismiss')" style="height: 30px; width: 30px;" class="dismiss"><img src="../assets/icons/x.png" style="opacity: 0.5; width: 12px; height: 12px;"/></vs-button>

            {{ message }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'messageOverlay',
    props: {
        message: String
    }
}
</script>

<style scoped>
.messageoverlay-container {
    z-index: 2090;
    position: fixed;
    top: 8vh;
    
    padding: 0px;
    
    font-weight: 600;
    text-align: center;
    
}

.message {
    background: white;
    color: var(--primary);
    border-radius: 15px;
    padding: 10px 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;

}

.dismiss {
    position: absolute;
    right: -10px;
    top: -10px;
}

/* MOBILE */
@media (max-width: 600px) {
    .messageoverlay-container {
        top: 13vh;
        padding-left: 25px;
        padding-right: 25px;
    }

    .message {
        padding: 10px 0px;
    }
}
</style>
<style>
.messageoverlay-container .vs-button {
    margin: 0!important;
    box-shadow: 0px 10px 20px -10px rgba(0,0,0, 0.5) !important;

}

body.app .messageoverlay-container {
    top: calc(10vh + env(safe-area-inset-top))!important;
    box-sizing: border-box!important;
    width: 100%!important;
}
</style>