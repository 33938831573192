<template>
<div class="profile-container">
    <vs-avatar circle badge badge-color="primary" size="100" @click="$refs.fileInput.click()" style="cursor: pointer;" :loading="uploading || loadingProfilePicture">
        <input type="file" accept="image/jpeg,image/png" ref="fileInput" @change="fileChanged" style="display: none;" />
        <img src="../../assets/images/default-avatar.webp" v-show="noProfilePicture" />

        <img class="loading" :src="pictureUrl" @error="pictureNotFound" @load="pictureLoaded" v-show="!noProfilePicture">
        <template #badge>
            <img src="../../assets/icons/edit-pic.png" style="margin: 2px; width: 16px;" />
        </template>
    </vs-avatar>
    <div style="display: flex; flex-direction: column; gap: 10px; text-align: center;">
        <h2>{{ user != null ? user.name : '' }}</h2>
        <h2>{{ user != null ? user.surname : '' }}</h2>
    </div>
    <vs-button circle block size="large" @click="$router.push({name: 'Sottoscrizione'})" v-if="showUpgradeButton">
        {{ $t('profile.subscription.upgrade').toUpperCase() }}
        <b>-{{ days }}</b>
    </vs-button>

    <div class="menu">
        <vs-button block transparent @click="$router.push({name: 'Sottoscrizione'})" v-if="!showUpgradeButton">
            <p>{{ $t('profile.subscription.title') }}</p> <img src="../../assets/icons/arrow-right.png" style="width: 16px;" />
        </vs-button>

        <vs-button block transparent @click="$router.push({name: 'Modifica profilo'})">
            <p>{{ $t('profile.userData') }}</p> <img src="../../assets/icons/arrow-right.png" style="width: 16px;" />
        </vs-button>
        <div class="lang">
            <p style="font-size: 0.8rem;">{{ $t('common.language') }}</p>
            <vs-select v-model="user.language_id" v-if="languages.length > 0">
                <vs-option v-for="l in languages" :key="'lang-' + l.id" :label="l.name" :value="l.id">
                    {{ l.name }}
                </vs-option>
            </vs-select>
        </div>

        <vs-button block transparent @click="$router.push({name: 'Notifiche'})">
            <p>
                {{ $t('common.notifications') }}
                <span class="notifications-counter" :class="{'active': notificationsCount > 0}">{{ notificationsCount > 10 ? '10+' : notificationsCount }}</span>
            </p>
            <img src="../../assets/icons/arrow-right.png" style="width: 16px;" />
        </vs-button>
        <vs-button block transparent @click="$router.push({name: 'Privacy Policy'})">
            <p>{{ $t('common.privacyPolicy') }}</p> <img src="../../assets/icons/arrow-right.png" style="width: 16px;" />
        </vs-button>
        <vs-button block transparent @click="$router.push({name: 'TOS'})">
            <p>{{ $t('common.termsOfService') }}</p> <img src="../../assets/icons/arrow-right.png" style="width: 16px;" />
        </vs-button>
    </div>
    <vs-checkbox style="width: 100%; padding: 0; margin: 0;" v-model="user.want_newsletter">{{ $t('profile.keepMeUpdated') }}</vs-checkbox>
    <vs-button circle block size="large" @click="logoutEvent" :loading="logoutLoading">{{ $t('profile.logout').toUpperCase() }}</vs-button>

    <vs-tooltip bottom shadow not-hover v-model="deleteAccountMenu" style="width: 100%; padding: 0; margin: 0;">
        <vs-button style="width: 100%; padding: 0; margin: 0;" circle block size="large" @click="deleteAccountMenu = true">{{ $t('profile.delete').toUpperCase() }}</vs-button>

        <template #tooltip>
            <div class="content-tooltip">
                <h4 class="center">
                    {{ $t('profile.accountDeletion.title') }}
                </h4>
                <p>
                    {{ $t('profile.accountDeletion.caption') }}
                </p>
                <footer>
                    <vs-button @click="deleteAccount" danger block>
                        {{ $t('common.delete') }}
                    </vs-button>
                    <vs-button @click="deleteAccountMenu=false" transparent dark block>
                        {{ $t('common.cancel') }}
                    </vs-button>
                </footer>
            </div>
        </template>
    </vs-tooltip>

</div>
</template>

<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
import {
    s3Client
} from '../../utils/S3Client';
import {
    GlobalEventEmitter
} from '../../utils/GlobalEventEmitter';
export default {
    name: "profile",
    props: {},
    components: {

    },

    data() {
        return {
            baseUri,
            logoutLoading: false,
            uploading: false,
            noProfilePicture: false,
            loadingProfilePicture: true,

            user: {
                language_id: '',
                want_newsletter: null,
                name: '',
                surname: ''
            },

            languages: [],

            deleteAccountMenu: false,
            notificationsCount: 0,
            t: Date.now(),

            days: 0,

            pictureUrl: null,
        }
    },

    computed: {
        showUpgradeButton() {
            return this.$parent.user.subscription == null && !this.$parent.user.is_admin && !this.$parent.user.is_partner && !this.$parent.user.hadPremium;
        }
    },

    watch: {
        'user.language_id'(n, o) {
            if (o != '') {
                this.saveChanges('language_id');
            }
        },

        'user.want_newsletter'(n, o) {
            console.log(n, o)
            if (o != null) {
                this.saveChanges('want_newsletter');
            }
        }
    },

    beforeDestroy() {
        var el = document.getElementsByClassName('explore-container')[0];
        if (el != undefined) {
            el.scrollTo(0, 0)
        }
    },

    methods: {
        async saveChanges(field) {
            let response = await apiCall('PATCH', '/users/info', {
                [field]: this.user[field]
            });
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('profile.messages.saved'),
                    color: 'success',
                    position: 'top-right'
                });
                GlobalEventEmitter.$emit('loadUserMeta')
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.cannotSave'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async getLanguages() {
            let response = await apiCall('GET', '/users/languages');
            if (response.status == 200) {
                this.languages = response.data.language;
            }
        },

        async getUser() {
            let response = await apiCall('GET', '/users/info');
            if (response.status == 200) {
                this.user.language_id = response.data.language.id;
                this.user.want_newsletter = response.data.want_newsletter;
                this.user.name = response.data.name;
                this.user.surname = response.data.surname;
                this.user.picture_id = response.data.picture_id;
                this.getNotificationsCount();
            }
        },

        pictureNotFound() {
            this.noProfilePicture = true;
            this.loadingProfilePicture = false;
        },

        pictureLoaded() {
            this.loadingProfilePicture = false;
        },

        logoutEvent() {
            this.logoutLoading = true;
            this.logout();
            this.logoutLoading = false;
        },

        async logout() {
            const response = await apiCall('POST', '/users/logout');
            if (response.status == 200 || response.status == 302) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('profile.messages.loggedOut'),
                    color: 'success',
                    position: 'top-right'
                });
                if(this.$IS_APP){
                    window.location.href = '/explore/login';
                } else {
                    window.location.href = '/';
                }
            }
        },

        async getNotificationsCount() {
            GlobalEventEmitter.$emit('getNotificationsCount');
            // use apiCall to call the login api
            const response = await apiCall('GET', '/notifications', {
                only_unread: true
            });

            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if (response.status == 200) {
                if (response.data.data != null) {
                    this.notificationsCount = response.data.data.length;
                    if (response.data.after != null) {
                        this.notificationsCount++; // 11 will be shown as 10+
                    }
                }

            }
        },

        async deleteAccount() {
            const response = await apiCall('DELETE', '/users/init');
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('profile.messages.deleted'),
                    color: 'success',
                    position: 'top-right'
                });
                this.logout();
                this.deleteAccountMenu = false;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.cannotDelete'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async fileChanged(e) {
            var file = e.target.files[0];
            // use apiCall to call the upload api passing a payload with the base64 image
            this.uploading = true;
            const response = await s3Client.uploadFile(file);
            
            // if status code is 200 show a success message
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('profile.messages.profilePictureUpdated'),
                    color: 'success',
                    position: 'top-right'
                });
                this.noProfilePicture = false;
                this.t = Date.now();
                this.user.picture_id = response.data.name;
                await this.saveChanges('picture_id');
                this.loadProfilePicture();
            } else if (response.status == 413) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.imageTooLarge'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.cannotUpdateProfilePicture'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            this.uploading = false;

        },

        async loadProfilePicture(){
            if(this.user.picture_id != null){
                const s3Result = await s3Client.getSignedDownloadURL({
                    name: this.user.picture_id,
                });

                if(s3Result && s3Result.url){
                    this.pictureUrl = s3Result.url;
                    return;
                }
            }

            this.pictureNotFound();
        }
    },

    async created() {
        this.getLanguages();
        await this.getUser();

        // load profile picture
        this.loadProfilePicture();
        

        // se registrato prima del 10 giugno 30 giorni, altrimenti invariato: DA RIMUOVERE TRA UN PO
        if (new Date(this.$parent.user.created_at) < new Date('2024-06-04')) {
            this.$TRIAL_DAYS = 30;
        } else {
            this.$TRIAL_DAYS = 14;
        }

        let days = this.$TRIAL_DAYS - Math.ceil(Math.abs((new Date()) - (new Date(this.$parent.user.created_at))) / (1000 * 60 * 60 * 24));
        if (days < 1) {
            days = 0;
        }
        this.days = days;

    }
}
</script>

<style scoped>
.notifications-counter {
    background: var(--grey-75);
    color: white;
    padding: 3px 5px;
    border-radius: 6px;
    margin-left: 5px;
}

.notifications-counter.active {
    background: var(--primary) !important;
}

img.profile {
    width: 20%;
    height: auto;
    border-radius: 100%;
}

.profile-container {

    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.menu {
    display: flex;
    flex-direction: column;
    width: 100%;
}

h2 {
    margin: 0;
}

.lang {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    border-bottom: 1px solid var(--grey-75);
    padding-top: 5px;
    padding-bottom: 5px;
}

.content-tooltip {
    padding: 10px;
    box-sizing: border-box;
}

.content-tooltip button {
    margin: 0;
    margin-bottom: 5px;
}

/* MOBILE */
@media (max-width: 600px) {
    .profile-container {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 110px !important;
        height: unset !important;
        width: 100% !important;
        padding-top: 20px;

    }

}
</style><style>
.profile-container .menu .vs-button__content {
    align-items: center;
    justify-content: space-between;
}

.profile-container .menu .vs-button {
    border-bottom: 1px solid var(--grey-75);
    border-radius: 0px !important;
}

.profile-container .lang .vs-select__input {
    background: transparent !important;
    text-align: right !important;
}
</style>
