<template>
    <div class="breadcumb-container">
        <img v-if="name != null" src="../assets/icons/back.png" style="width: 24px;" @click="back"/>
        
        <h4 class="bold" v-if="name != null" @click="back">{{ name }}</h4>
    </div>
</template>
<style scoped>
.breadcumb-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--grey-75);
    margin-bottom: 10px;
    position: fixed;
    background: white;
    z-index: 3000;
    cursor: pointer;
    
}



/* MOBILE */
@media (max-width: 600px) {
    .breadcumb-container {
        padding-left: 20px;
        box-sizing: border-box;
        min-height: 50px;
    }
}

.tourial-app.tourial-native .breadcumb-container {
    background: white!important;
    top: 0;
    /* fill the notch */
    padding-top: env(safe-area-inset-top)!important;
    box-sizing: border-box;
    min-height: 50px;
}
</style>
<script>
export default {
  name: 'breadCumb',
  props: {
    name: String,
    back: Function
  }
}
</script>
