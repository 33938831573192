<template>
<div class="notification-container">
    <div class="sx" :style="{cursor: notification.call_to_action_type == 'URL' ? 'pointer' : null}" @click="handleClick">
        <img src="../assets/icons/notification.png" style="width: 36px;" />
        <div class="content">
            <p class="bold">{{notification.title}}</p>
            <p class="light">{{notification.text}}</p>
        </div>
    </div>

    <vs-button @click="markAsRead" v-if="notification.dismissable !== false" transparent style="height: 40px;"><img src="../assets/icons/close.png" style="width: 24px;" /></vs-button>

</div>
</template>

<script>
import {
    GlobalEventEmitter
} from '../utils/GlobalEventEmitter';
import {
    apiCall
} from '../utils/ApiMiddleware';
export default {
    name: 'notification',

    props: {
        notification: {
            type: Object,
            required: true
        }
    },

    methods: {
        handleClick() {
            if (this.notification.call_to_action_type == 'URL') {
                if(this.notification.call_to_action_value.startsWith('/')){
                    this.$router.push(this.notification.call_to_action_value);
                } else {
                    window.location.href = this.notification.call_to_action_value;
                }
            } else if(this.notification.call_to_action_type == 'ROUTE') {
                this.$router.push({
                    name: this.notification.call_to_action_value
                });
            }
        },

        async markAsRead() {
            const loading = this.$vs.loading();
            const response = await apiCall('POST', '/notifications/' + this.notification.id + '/read');
            loading.close();
            if (response.status == 200) {
                this.$emit('dismissed');
                GlobalEventEmitter.$emit('getNotificationsCount');
            }

        },
    }
}
</script>

<style scoped>
.content p {
    overflow-wrap: break-word;
    width: 100%;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.notification-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    padding: 20px 15px;

}

.sx {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    width: calc(100% - 50px);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: calc(100% - 50px);
}

p {
    margin: 0;
}
</style>
