<template>
<div class="wizard-container">
    <carousel ref="wizard" class="steps" :autoplay="false" :nav="false" :items="1" :stagePadding="0" >
        <div class="step">
            <div class="content">
                <img src="../../assets/images/Scegli-i-posti-da-visitare.png"  />
                <p class="text-center font-bold text-tourial md:text-left mb-0">Con Tourial</p>

                <h1 class="mb-4 text-center text-2xl font-bold mt-0 text-gray-800 sm:text-3xl md:mb-6 md:text-left">Scegli i posti da visitare</h1>

                <p class="mb-2 text-gray-500 sm:text-lg text-center md:text-left">
                    Attiva la <span class="text-tourial text-bold">geolocalizzazione</span> e utilizza la mappa della funzionalità <span class="text-tourial text-bold">"Esplora"</span> di Tourial per trovare posti noti e meno noti e decidere dove andare. <br />Leggi le <span class="text-tourial text-bold">schede informative</span>. Salva i tuoi <span class="text-tourial text-bold">preferiti</span>. <br />

                </p>
            </div>
            <div class="buttons">
                <vs-button size="large" block class="bold" @click="next">{{ $t('common.next') }}</vs-button>
                <vs-button transparent size="large" @click="skip">{{ $t('common.skip') }}</vs-button>
            </div>
        </div>
        <div class="step">
            <div class="content">
                <img src="../../assets/images/Personalizza-il-tuo-viaggio.png" />
                <p class="text-center font-bold text-tourial md:text-left mb-0">Con Tourial</p>

                <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 mt-0 sm:text-3xl md:mb-6 md:text-left">Personalizza il tuo viaggio</h1>

                <p class="mb-2 text-gray-500 sm:text-lg text-center md:text-left">
                    <span class="text-tourial text-bold">Crea il tuo programma di viaggio</span> e organizza tutte le tappe che ti interessano. <br />Con la funzionalità <span class="text-tourial text-bold">“Itinerario”</span>, pianificare una gita o una vacanza non è mai stato così <span class="text-tourial text-bold">facile e veloce</span>.
                </p>
            </div>
            <div class="buttons">
                <vs-button size="large" block class="bold" @click="next">{{ $t('common.next') }}</vs-button>
                <vs-button transparent size="large" @click="skip">{{ $t('common.skip') }}</vs-button>
            </div>
        </div>
        <div class="step">
            <div class="content">
                <img src="../../assets/images/Entra-a-far-parte-della-community.png"  />
                <p class="text-center font-bold text-tourial mb-0 md:text-left">Con Tourial</p>

                <h1 class="mb-4 text-center text-2xl font-bold mt-0 text-gray-800 sm:text-3xl md:mb-6 md:text-left">Entra a far parte di una community</h1>

                <p class="mb-2 text-gray-500 sm:text-lg text-center md:text-left">
                    In <span class="text-tourial text-bold">Tourial</span> puoi <span class="text-tourial text-bold">recensire</span> tutti i posti che visiterai e <span class="text-tourial text-bold">scambiare</span> consigli di viaggio con altri appassionati viaggiatori come te!
                    <br />Cosa c'è di più bello di un viaggio se non condividerlo?
                </p>
            </div>
            <div class="buttons">
                <vs-button size="large" block class="bold" @click="skip">{{ $t('common.next') }}</vs-button>
                <vs-button transparent size="large" @click="skip">{{ $t('common.skip') }}</vs-button>
            </div>
        </div>
    </carousel>
    <Footer />
</div>
</template>
<style scoped>
@import '../../tailwind.css';
</style>
<script>
import carousel from 'vue-owl-carousel'
import Footer from '../Footer.vue';

export default {
    name: "wizard",
    props: {},
    components: {
        carousel,
        Footer
    },

    mounted() {
        localStorage.setItem('WIZARD_DONE', true);
    },
    methods: {
        skip() {
            this.$router.push({
                name: 'Benvenuto'
            })
        },
        next() {
            var el = document.querySelector('.owl-dot.active').nextSibling;
            if (el != null) {
                el.click();
            }
        }
    }
}
</script>

<style scoped>
.step {
    width: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
}

.steps {
    width: 20vw;
}

.step .content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.wizard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    height: 100%;

}

/* DESKTOP */
@media (min-width: 1024px) {
    .wizard-container {
        width: 350px;
    }
}

/* MOBILE */
@media (max-width: 600px) {
    .wizard-container {
        justify-content: center;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        height: unset !important;
        width: 100% !important;
        height: 100% !important;

    }

    .steps {
        width: 100%;
    }

    .step {
        width: 100%;
    }

    .step img {
        height: 30vh !important;
        width: auto !important;
    }
}
</style><style>
.owl-carousel .owl-stage {
    display: flex;
    margin-left: 7px!important;
}



.owl-carousel .owl-item:not(.active){
    opacity: 0;
}


.step {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
}
</style>
