<template>
<div class="subscriptionconfirmed-container">
    <div class="top">
        <div style="display: flex; justify-content: center;">
            <img src="../../assets/images/subscription_confirmed.png" style="width: 60%;" />

        </div>
        <h2 style="text-align: center;">{{ $t('profile.subscription.confirmed.title') }}</h2>
        <p style="text-align: center;">{{ $t('profile.subscription.confirmed.caption') }}</p>
    </div>
    <div class="bottom">
        <vs-button size="large" @click="$router.push({name: 'Esplora'})">{{ $t('profile.subscription.confirmed.startExploring').toUpperCase() }}</vs-button>
    </div>

</div>
</template>

    
<script>
import {
    GlobalEventEmitter
} from '../../utils/GlobalEventEmitter.js';
export default {
    name: "subscriptionConfirmed",
    props: {},
    components: {},
    data() {
        return {

        }
    },
    methods: {

    },

    mounted: function() {
        GlobalEventEmitter.$emit('loadUserMeta');
    },
}
</script>

    
<style scoped>
.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.subscriptionconfirmed-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

p,h2 {
    margin: 0;
}

/* MOBILE */
@media (max-width: 600px) {
    .subscriptionconfirmed-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;

    }

}
</style>
