<template>
<div class="newreview-container">
    <input type="file" accept="image/jpeg,image/png" multiple ref="fileInput" @change="fileChanged" style="display: none;" />
    <div class="header">
        <h2 style="margin: 0;">{{ $t('reviews.new.title') }}</h2>
        <p style="margin: 0;">{{ $t('reviews.new.caption') }}</p>
    </div>
    <Stars :stars="review.rating" @change="updateStars" />
    <textarea :disabled="review.id == null" v-model="review.description" placeholder="Scrivi la tua recensione"></textarea>
    <div class="header">
        <h3 style="margin: 0;">{{ $t('reviews.new.shareImages') }}</h3>
        <p style="margin: 0;">{{ $t('reviews.new.maxImages') }}</p>
    </div>
    <div class="images">
        <template v-if="review.pictures != null">
            <div class="image-container" style="position: relative;" :key="'picture-'+i"  v-for="(url, i) in pictures">
                <!-- delete button-->
                <vs-button danger @click="deletePicture(i)" style="position: absolute; top: 0; right: 0; width: 20px; height: 20px; z-index: 10; border-radius: 0; border-top-right-radius: 10px; border-bottom-left-radius: 10px;"><img src="../../assets/icons/x.png" style="height: 15px; width: 15px;" /></vs-button>
                <img style="max-height: 60px; width: auto;" :src="url" />
            </div>
        </template>
        
        <vs-button color="#000000" @click="$refs.fileInput.click()"><img src="../../assets/icons/new.png" style="height: 30px; width: 30px;" /></vs-button>
    </div>
    <vs-button size="large" block :loading="review.id == null || isSaving" @click="publishReview">{{ $t('reviews.new.publish').toUpperCase() }}</vs-button>
    <vs-button size="large" danger border block :loading="isDeleting" v-if="review.id != null && review.is_published" @click="deleteReview">{{ $t('reviews.new.delete').toUpperCase() }}</vs-button>
</div>
</template>

<script>
import Stars from '../Stars.vue';
import {
    apiCall, baseUri
} from '../../utils/ApiMiddleware';
import {
    s3Client
} from '../../utils/S3Client';
export default {
    name: "newReview",

    components: {
        Stars
    },
    data() {
        return {
            baseUri,
            review: {
                rating: 0,
                description: '',
                id: null,
            },
            isSaving: false,
            isDeleting: false,
            timer: null,
            pictures: []
        }
    },

    mounted() {
        if (this.$route.params.reviewId != null) {
            this.getReview();
        } else {
            this.createReview();
        }

    },

    async beforeDestroy() {
        if (this.review.id != null) {
            if(await this.saveReview()){
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('reviews.new.messages.reviewDraftSaved'),
                    color: 'success',
                    position: 'top-right'
                })
            }
        }
    },
    methods: {
        updateStars(stars) {
            if (this.review.id == null) {
                return;
            }
            this.review.rating = stars;
        },

        async loadReviewImages(){
            this.pictures = await Promise.all(this.review.pictures.map(async (picture) => {
                const s3Result = await s3Client.getSignedDownloadURL({
                    name: picture
                });

                if(s3Result && s3Result.url){
                    return s3Result.url;
                }

                return null;
            }))
        },

        async fileChanged(e) {
            const files = e.target.files;
            var responses = [];
            const loading = this.$vs.loading();

            const uploadableImages = 5 - (this.review.pictures ? this.review.pictures.length : 0);
            if(files.length > uploadableImages){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.limitReached'),
                    color: 'danger',
                    position: 'top-right'
                });
                loading.close();
                return;
            }

            // check if one or more images exceed the size limit (10MB)
            for(let i = 0; i < files.length; i++){
                if(files[i].size > 1024 * 1024 * 10){
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('reviews.new.messages.imageTooLarge'),
                        color: 'danger',
                        position: 'top-right'
                    });
                    loading.close();
                    return;
                }
            }

            for(let i = 0; i < files.length; i++){
                var file = files[i];

                const response = await s3Client.uploadFile(file);


                if(response.status == 200){
                    if(this.review.pictures == null){
                        this.review.pictures = [];
                    }
                    this.review.pictures.push(response.data.name);
                }

                responses.push(response);
            }
            // use apiCall to call the upload api passing a payload with the base64 image

            
            // if status code is 200 show of all images uploaded
            if(responses.filter(e => e.status != 200).length == 0){
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('reviews.new.messages.imageAdded'),
                    color: 'success',
                    position: 'top-right'
                });
            } else if(responses.filter(e => e.status == 508).length > 0){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.limitReached'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if(responses.filter(e => e.status == 413).length > 0){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.imageTooLarge'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if(responses.filter(e => e.status != 0).length > 0){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotAddImage'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            this.loadReviewImages();
            loading.close();

        },

        async getReview() {
            const response = await apiCall('GET', '/reviews/' + this.$route.params.reviewId);
            if (response.status == 200) {
                this.review = response.data;
                this.loadReviewImages();
            } else if (response.status == 404) {
                // create new review
                console.log(response)
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotGetDraft'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        async createReview() {
            const response = await apiCall('POST', '/reviews', {
                poi_id: this.$route.params.id
            });
            if (response.status == 200) {
                this.review = {
                    rating: 0,
                    description: '',
                    id: response.data.id,
                };
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotCreateDraft'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        async publishReview(){
            this.review.is_published = true;
            if(await this.saveReview()){
                // show a success message to the user
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('reviews.new.messages.reviewSaved'),
                    color: 'success',
                    position: 'top-right'
                })
                this.review.id = null; // to avoid double draft saving
                this.$router.push({name: 'Scheda del luogo', params: {id: this.$route.params.id}});
            }
        },

        async deleteReview(){
            const response = await apiCall('DELETE', '/reviews/' + this.review.id);
            if(response.status == 200){
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: this.$t('reviews.new.messages.reviewDeleted'),
                    color: 'success',
                    position: 'top-right'
                })
                this.review.id = null;
                this.$router.push({name: 'Scheda del luogo'})
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotDeleteReview'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        async deletePicture(index){
            const loading = this.$vs.loading();
            const response = await apiCall('DELETE', '/reviews/' + this.review.id + '/pictures/' + this.review.pictures[index]);
            loading.close();
            if(response.status == 200){
                this.review.pictures = this.review.pictures.filter((e, i) => i != index);
                // also remove the image from the images array
                this.pictures = this.pictures.filter((e, i) => i != index);
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotDeleteImage'),
                    color: 'danger',
                    position: 'top-right'
                })
            }
        },

        async saveReview() {
            if(this.review.id == null){
                return;
            }
            this.isSaving = true;
            clearTimeout(this.timer);
            this.timer = null;
            const response = await apiCall('PATCH', '/reviews/' + this.review.id, this.review);
            this.isSaving = false;
            if (response.status == 200) {
                
                return true;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.new.messages.cannotSave'),
                    color: 'danger',
                    position: 'top-right'
                })

            }
            return false;
        },

    },

    watch: {
        review: {
            deep: true,
            handler: function (n, o) {
                console.log(n)
                if (n.id != null && o.id != null) {
                    if (this.timer != null) {
                        clearTimeout(this.timer);
                        this.timer = null;
                    }
                    this.timer = setTimeout(() => {
                        this.saveReview();
                    }, 5000);

                }
            }
        }
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.newreview-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}

.images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.images img,
.images .vs-button {
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

/* MOBILE */
@media (max-width: 600px) {
    .newreview-container {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
height: unset!important;
        width: 100%!important;
    }

}
</style>
