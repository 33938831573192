<template>
<div class="editpoi-container">
    <vs-dialog blur prevent-close v-model="uploadPictureDialog">
        <template #header>
            <h4 class="not-margin">
                Carica un <b>immagine</b>
            </h4>
        </template>

        <div class="con-form" style="display: flex; flex-direction: column; gap: 30px;">
            <vs-input v-model="uploadPictureDialogCaption" label="Descrizione (Origine)" placeholder="Google Places" block />
            <input type="file" multiple ref="picturesInput" accept="image/*" style="display: none;" />
            <vs-button @click="$refs.picturesInput.click()" size="large" block>Seleziona immagini</vs-button>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="uploadPictures" size="large">
                    Carica
                </vs-button>

            </div>
        </template>
    </vs-dialog>
    <DialogWrapper v-if="dialogComponent != null" :component="dialogComponent" :callback="dialogCallback" :is-mobile="false" :data="poi" @close="dialogComponent = null" :preventClose="true"/>
    <div class="top">

        <div class="fields">

            <div class="field">
                <p>Target</p>
                <LazyDropdown v-model="poi.target_id" :value="poi.target_id" endpoint="/targets" />
            </div>

            <div class="field">
                <p>{{ $t('common.city') }}</p>
                <LazyDropdown v-model="poi.city_id" :value="poi.city_id" endpoint="/places/cities" />
            </div>
            <div class="field">
                <p>{{ $t('poi.yearOfConstruction') }}</p>
                <vs-input type="text" v-model="poi.year_built" />
            </div>
            <div class="field">
                <p>{{ $t('poi.author') }}</p>
                <vs-input type="text" v-model="poi.author" />
            </div>
            <div class="field">
                <p>Website</p>
                <vs-input type="text" v-model="poi.website" />
            </div>
            <div class="field">
                <p>Indirizzo</p>
                <vs-input type="text" v-model="poi.street" />
            </div>
            <div class="field">
                <p>Lat</p>
                <vs-input type="text" v-model="poi.point.y" />
            </div>
            <div class="field">
                <p>Lon</p>
                <vs-input type="text" v-model="poi.point.x" />
            </div>

            <div class="field" v-if="poi.id != null">
                <p>{{ $t('common.language') }}</p>
                <vs-select v-model="lang" v-if="languages.length > 0">
                    <vs-option v-for="l in languages" :key="'lang-' + l.id" :label="l.name" :value="l.id">
                        {{ l.name }}
                    </vs-option>
                </vs-select>
            </div>

            <div class="field">
                <p>Nome</p>
                <vs-input v-if="lang != null" type="text" v-model="poi.poi_localization_infos.find(e => e.language_id == lang).name" />
            </div>

            <p>Descrizione</p>
            <textarea v-if="lang != null" v-model="poi.poi_localization_infos.find(e => e.language_id == lang).description">

            </textarea>

            <vs-checkbox block v-model="poi.place_to_discover">{{ $t('explore.placeToDiscover') }}</vs-checkbox>
            <vs-checkbox block v-model="poi.published">Pubblicato</vs-checkbox>

            <div class="images">
                <div class="image" v-for="picture in pictures" :key="'picture-'+picture.name" style="position: relative;">
                    <vs-button @click="deletePicture(picture)" color="danger" size="small" style="position: absolute; top: -10px; right: -10px;">X</vs-button>
                    <img style="max-height: 60px; width: auto;" :src="picture.url" class="loading" />
                </div>
            </div>

            <vs-button border size="large" @click="showGooglePlacesDialog">
                <img style="height: 24px; width: auto; margin-right: 5px; " src="../../assets/icons/menu/google_active.png" />
                GOOGLE PLACES
            </vs-button>
            <vs-button border size="large" @click="uploadPictureDialog = true">
                <img style="height: 24px; width: auto;  margin-right: 5px;" src="../../assets/icons/upload.png" />
                CARICA FOTO
            </vs-button>
            <vs-button size="large" :loading="loading" @click="editPoiEvent">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>
            <vs-button danger border size="large" :loading="deleting" @click="deletePoiEvent">{{ $t('common.delete').toUpperCase() }}</vs-button>
        </div>
    </div>

</div>
</template>

<script>
import {
    apiCall
} from '../../utils/ApiMiddleware';
import {
    s3Client
} from '../../utils/S3Client';
import GooglePlacesNearby from '../dialogs/GooglePlacesNearby.vue';
import LazyDropdown from '../LazyDropdown.vue';
import DialogWrapper from '../DialogWrapper.vue';

export default {
    name: "editPoi",
    props: {
        poi: {
            type: Object,
            required: true
        },
        editPoi: {
            type: Function,
            required: true
        },

        deleted: {
            type: Function,
            required: true
        },

        user: {
            type: Object,
            required: false,
            default: null
        },

        adminFilters: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: {
        LazyDropdown,
        DialogWrapper
    },
    data() {
        return {
            loading: false,
            languages: [],
            lang: null,
            deleting: false,

            dialogComponent: null,

            pictures: [],

            uploadPictureDialog: false,
            uploadPictureDialogCaption: '',
            pendingUploads: 0
        }
    },

    watch: {
        uploadPictureDialog(val) {
            if (val) {
                this.uploadPictureDialogCaption = 'Google Places';
            }
        },

        pendingUploads(val) {
            if (val == 0 && this.uploadPictureDialog) {
                this.uploadPictureDialog = false;
            }
        }
    },

    async mounted() {
        const response = await apiCall('GET', '/users/languages');
        if (response.status != 200) {
            this.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: this.$t('common.messages.somethingWentWrong'),
                text: 'Impossibile ottenere le lingue'
            })
            return;
        }
        this.languages = response.data.language;
        this.lang = this.languages.find(e => e.id == this.user.language.id).id

        if (this.poi.id == null) {
            this.poi.poi_localization_infos = [{
                language_id: this.lang,
                name: '',
                description: ''
            }]
        }

        if (this.poi.pictures && this.poi.pictures.length > 0) {
            // load images from S3
            let pictures = [];

            for (let i = 0; i < this.poi.pictures.length; i++) {
                const image = this.poi.pictures[i];
                const s3Response = await s3Client.getSignedDownloadURL({
                    name: image.name
                });
                if (s3Response && s3Response.url) {
                    pictures.push({
                        name: image.name,
                        url: s3Response.url,
                        description: image.description,
                        transition: 'kenburn'
                    })
                }
            }

            this.pictures = pictures;
        }
    },

    methods: {
        showGooglePlacesDialog() {
            this.dialogComponent = GooglePlacesNearby;
        },

        dialogCallback(data, close) {
            console.log(data, close);
            if (close) {
                this.dialogComponent = null;
            }

            if (data && data.length) {
                this.$router.push({
                    name: 'ADM_GooglePlaces',
                    params: {
                        inputPlaces: data.map((e) => ({
                            ...e,
                            poi: this.poi,
                            localized_name: this.poi.poi_localization_infos.find(e => e.language_id == this.lang).name,
                        })),

                        poisAdminFilters: this.adminFilters,

                        poiAdmin: this.poi,
                    }
                })
            }

        },

        async deletePoiEvent() {
            this.deleting = true;
            const response = await apiCall('DELETE', '/backoffice/pois/' + this.poi.id);
            this.deleting = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'POI eliminato con successo',
                    color: 'success',
                    position: 'top-right'
                });
                this.deleted();
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare il POI',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async editPoiEvent() {
            this.poi.point.x = parseFloat(this.poi.point.x);
            this.poi.point.y = parseFloat(this.poi.point.y);
            if (this.poi.id == null) {
                for (var k in this.languages) {
                    if (this.languages[k].id == this.lang) {
                        continue;
                    }
                    this.poi.poi_localization_infos.push({
                        language_id: this.languages[k].id,
                        name: this.poi.poi_localization_infos.find(e => e.language_id == this.lang).name,
                        description: this.poi.poi_localization_infos.find(e => e.language_id == this.lang).description
                    })
                }
            }
            this.loading = true;
            await this.editPoi(this.poi);
            this.loading = false;

        },

        deletePicture(picture) {
            this.pictures = this.pictures.filter(e => e.name != picture.name);
            this.poi.pictures = this.poi.pictures.filter(e => e.name != picture.name);
        },

        async uploadPictures() {
            // load all pictures as blobs 
            const pictures = this.$refs.picturesInput.files;
            this.pendingUploads = pictures.length;
            // for each image upload it using s3client and then store
            /*
            this.pictures.push({
                                name: urlResult.data.name,
                                url: URL.createObjectURL(picture),
                                description: JSON.parse(JSON.stringify(this.uploadPictureDialogCaption)), // deep copy
                            });

                            if (!this.poi.pictures) {
                                this.poi.pictures = [];
                            }

                            this.poi.pictures.push({
                                name: urlResult.data.name,
                                description: this.uploadPictureDialogCaption
                            });*/

            for(var i = 0; i < pictures.length; i++){
                const picture = pictures[i];
                const uploadResult = await s3Client.uploadFile(picture);

                if(uploadResult && uploadResult.status == 200 && uploadResult.data.name){
                    this.pictures.push({
                        name: uploadResult.data.name,
                        url: URL.createObjectURL(picture),
                        description: JSON.parse(JSON.stringify(this.uploadPictureDialogCaption)), // deep copy
                    });

                    if (!this.poi.pictures) {
                        this.poi.pictures = [];
                    }

                    this.poi.pictures.push({
                        name: uploadResult.data.name,
                        description: this.uploadPictureDialogCaption
                    });
                } else {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: 'Impossibile caricare l\'immagine ' + i,
                        color: 'danger',
                        position: 'top-right'
                    });
                }

                this.pendingUploads--;
            }
        }

    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.editpoi-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .editpoi-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;

    }

}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}
</style><style>
.editpoi-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.editpoi-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.editpoi-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}

.images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-x: auto;
}

.images img {
    height: 50px;
    width: auto;
    border-radius: 10px;
}

.images::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--vs-primary), 0.5)!important;
}
</style>
