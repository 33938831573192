<template>
    <div class="administrationnotifications-container">
        <slideout-panel></slideout-panel>
    
        <vs-row justify="flex-end" style="width:100%;">
            <vs-button size="large" @click="createNew">Crea Nuovo</vs-button>
        </vs-row>
    
        <vs-table v-model="selected" style="height: 80vh; width: 100%; margin-top: 20px;">
            <template #thead>
                <vs-tr>
                    <vs-th>
                        Title
                    </vs-th>
                    <vs-th>
                        Text
                    </vs-th>
                    <vs-th>
                        CTA
                    </vs-th>
                </vs-tr>
            </template>
            <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in notifications" :data="tr" :is-selected="selected == tr">
                    <vs-td>
                        {{ tr.localized != undefined ? tr.localized.find(e => e.language_id == user.language.id).title : '-' }}
                    </vs-td>
                    <vs-td>
                        {{ tr.localized != undefined ? tr.localized.find(e => e.language_id == user.language.id).text : '-'}}
                    </vs-td>
                    <vs-td>
                        [{{ tr.call_to_action_type }}] {{ tr.call_to_action_value }}
                    </vs-td>
                </vs-tr>
            </template>
            <template #footer>
                <vs-row justify="center" align="center">
                    <vs-button :disabled="result == null || result.before == null" @click="previousPage">
                        <img src="../../assets/icons/previous_page.png" />
                    </vs-button>
                    <vs-button :disabled="result == null || result.after == null" @click="nextPage">
                        <img src="../../assets/icons/next_page.png" />
                    </vs-button>
                </vs-row>
    
            </template>
        </vs-table>
    </div>
    </template>
    
        
    <script>
    import {
        apiCall
    } from '../../utils/ApiMiddleware';
    import EditNotification from '../../components/elements/EditNotification.vue';
    export default ({
        data() {
            return {
                notifications: [],
                selected: null,
                filters: {},
                result: null,
                panel: null
            }
        },
        props: {
            user: {
                type: Object,
                required: false
            }
        },
    
        mounted() {
            this.getNotifications(this.filters)
        },
    
        methods: {
            createNew() {
                this.selected = {
                    id: null,
                    call_to_action_type: '',
                    call_to_action_value: '',
                    localized: [],
                    retroactive: false
                }
            },
            previousPage() {
                this.filters.before = this.result.before;
                this.filters.after = null;
                this.filters.__ob__.dep.notify();
            },
    
            nextPage() {
                this.filters.after = this.result.after;
                this.filters.before = null;
                this.filters.__ob__.dep.notify();
            },
    
            async getNotifications(p) {
                this.selected = null;
                const loading = this.$vs.loading();
                const response = await apiCall('GET', '/backoffice/notifications', p);
                loading.close();
                // if status code is 200, show a success message 
                if (response.status == 200) {
                    console.log(response.data.data)
                    this.notifications = response.data.data;
                    this.result = response.data;
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: 'Impossibile caricare le notifiche',
                        color: 'danger',
                        position: 'top-right'
                    });
                }
    
            },
    
            async editNotification(notification) {
                const response = await apiCall('POST', '/backoffice/notifications', notification);
                // if status code is 200, show a success message 
                if (response.status == 200) {
                    
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: 'Notifica creata con successo',
                        color: 'success',
                        position: 'top-right'
                    });
                    await this.getNotifications();
                    this.selected = this.notifications.find(e => e.id == response.data.id);
                } else if (response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: 'Impossibile creare la notifica',
                        color: 'danger',
                        position: 'top-right'
                    });
                }
            }
        },
    
        watch: {
            filters(n) {
                this.getNotifications(n);
            },
            async selected(n) {
                
                console.log(n)
                var vm = this;
    
                if (this.panel != null) {
                    await this.panel.hide();
                    this.panel = null;
                }

                if (n == null) {
                    return;
                }
                this.panel = await this.$showPanel({
                    component: EditNotification,
                    openOn: 'right',
                    props: {
                        notification: n,
                        editNotification: this.editNotification,
                        user: this.user,
                        deleted: () => {
                            this.getNotifications()
                        }
                    }
                });
                console.log(this.panel)
    
                this.panel.promise
                    .then(() => {
                        vm.selected = null;
                    });
    
            }
        },
    })
    </script>
    
        
    <style scoped>
    .administrationnotifications-container {
        padding-left: 70px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
    }
    </style>
    