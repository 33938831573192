<template>
<div class="review-container">

    <vs-avatar circle size="50">
        <img src="../assets/images/default-avatar.webp" v-show="noProfilePicture || review.user.picture == ''" />
        <img ref="userPic" class="loading" :src="reviewUserPictureUrl" @load="previewLoaded" @error="pictureNotFound" v-if="!noProfilePicture && review.user.picture != ''">
    </vs-avatar>
    <div class="review-content">
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
            <p style="margin: 0;">{{ review.user.name }} {{ review.user.surname }}</p>
            <p class="light" style="font-size: 12px; margin: 0;">{{ howManyTimeAgo(new Date(review.updated_at)) }} </p>
        </div>
        <Stars :stars="review.rating" />
        <p style="margin: 0; overflow-wrap: break-word; width: 100%; white-space: -moz-pre-wrap; white-space: -pre-wrap; white-space: -o-pre-wrap; word-wrap: break-word;">
            {{ fullDescription ? review.description : review.description.substr(0,200) }}

        </p>
        <a style="cursor: pointer;" @click="fullDescription = true" v-if="!fullDescription && review != null && review.description.length > 200">{{ $t('common.showMore') }}</a>
        <a style="cursor: pointer;" @click="fullDescription = false" v-if="fullDescription">{{ $t('common.showLess') }}</a>
        <div class="images" :id="'lightgallery-' + cid">
            <template v-if="pictures != null">
                <a :href="url" v-for="(url, i) in pictures" :key="'picture-'+i" :data-sub-html="buildDescription()">
                    <img style="max-height: 60px; max-width: 100%; min-width: 50px; width: auto;" :data-src="url" :src="url" class="loading" @load="previewLoaded" @error="previewFailed"/>
                </a>
            </template>

        </div>

        <vs-button :disabled="review.user.id == user.id" class="like" @click="toggleReviewLike" transparent size="large" :style="{'color': (!review.liked_by_me ? 'black!important' : undefined)}">
            <img v-if="review.liked_by_me" src="../assets/icons/like.png" style="width: 19px;" />
            <img v-else src="../assets/icons/like_empty.png" style="width: 19px;" />
            {{ $t('reviews.useful') }} ({{ review.number_of_likes + (review.liked_by_me ? 1 : 0) }})
        </vs-button>

    </div>
</div>
</template>

<script>
import {
    s3Client
} from '../utils/S3Client';
import {
    v4 as uuidv4
} from 'uuid';

import DOMPurify from 'dompurify';
import {
    baseUri,
    apiCall
} from '../utils/ApiMiddleware';
import Stars from './Stars.vue';


export default {
    name: "review",
    props: {
        review: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        }
    },
    components: {
        Stars,
    },
    data() {
        return {
            baseUri: baseUri,
            noProfilePicture: false,
            fullDescription: false,
            cid: uuidv4(),
            pictures: null,
            reviewUserPictureUrl: null,
        }
    },

    async mounted() {
        await this.loadUserPicture();
        console.log(this.review.pictures)
        if (this.review.pictures != null && this.review.pictures.length > 0) {
            // get the pictures
            this.pictures = await Promise.all(this.review.pictures.map(async (picture) => {
                const s3Result = await s3Client.getSignedDownloadURL({
                    name: picture
                });

                if(s3Result && s3Result.url){
                    return s3Result.url;
                }

                return null;
            }))



            // in next tick, init lightGallery, in this way we are sure DOM is updated
            this.$nextTick(() => {
                window.lightGallery(document.getElementById('lightgallery-' + this.cid), {
                    thumbnail: true,
                    animateThumb: false,
                    showThumbByDefault: false,
                    download: true,
                    selector: 'a'
                });
            });
        }
    },

    methods: {
        previewFailed(e){
            // remove image
            const url = e.currentTarget.src;
            this.pictures = this.pictures.filter(p => p != url);
        },

        async loadUserPicture() {
            if (this.review.user.picture != null && this.review.user.picture != '') {
                const s3Result = await s3Client.getSignedDownloadURL({
                    name: this.review.user.picture
                });

                if(s3Result && s3Result.url){
                    this.reviewUserPictureUrl = s3Result.url;
                    return;
                }
            }

            this.pictureNotFound();
        },

        previewLoaded(e) {
            e.currentTarget.classList.remove('loading');
        },

        buildDescription() {
            var description = DOMPurify.sanitize(this.review.description);
            var name = DOMPurify.sanitize(this.review.user.name);
            var surname = DOMPurify.sanitize(this.review.user.surname);
            return '<h4 style="margin: 0;">' + name + ' ' + surname + '</h4><p style="margin: 0;">' + description + '</p>';
        },

        async toggleReviewLike() {
            if (this.review == null) {
                return;
            }
            this.review.liked_by_me = !this.review.liked_by_me;

            // call the api to toggle the like
            const response = await apiCall('PUT', '/reviews/' + this.review.id + '/like', {
                liked: this.review.liked_by_me
            })
            if (response.status == 200) {
                // success
                if (this.review.liked_by_me) {
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: this.$t('reviews.messages.markedAsUseful'),
                        color: 'success',
                        position: 'top-right'
                    })
                    this.number_of_likes++;
                } else {
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: this.$t('reviews.messages.unmarkedAsUseful'),
                        color: 'success',
                        position: 'top-right'
                    })
                    this.number_of_likes--;
                }

            } else if (response.status != 0) {
                // show an error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('reviews.messages.cannotMarkAsUseful'),
                    color: 'danger',
                    position: 'top-right'
                })
                this.review.liked_by_me = !this.review.liked_by_me; // return to previous state
            }
        },

        pictureNotFound() {
            this.noProfilePicture = true;
        },

        howManyTimeAgo(date){
            // return the number of minutes, hours, days, weeks or months ago
            let now = new Date();
            let diff = now - date;
            let minutes = Math.floor(diff / 1000 / 60);
            let hours = Math.floor(diff / 1000 / 60 / 60);
            let days = Math.floor(diff / 1000 / 60 / 60 / 24);
            let weeks = Math.floor(diff / 1000 / 60 / 60 / 24 / 7);
            let months = Math.floor(diff / 1000 / 60 / 60 / 24 / 30);
            if (months > 0) {
                return months + ' ' + (months > 1 ? this.$t('common.monthsAgo') : this.$t('common.monthAgo'));
            } else if (weeks > 0) {
                return weeks + ' ' + (weeks > 1 ? this.$t('common.weeksAgo') : this.$t('common.weekAgo'));
            } else if (days > 0) {
                return days + ' ' + (days > 1 ? this.$t('common.daysAgo') : this.$t('common.dayAgo'));
            } else if (hours > 0) {
                return hours + ' ' + (hours > 1 ? this.$t('common.hoursAgo') : this.$t('common.hourAgo'));
            } else if (minutes > 0) {
                return minutes + ' ' + (minutes > 1 ? this.$t('common.minutesAgo') : this.$t('common.minuteAgo'));
            } else {
                return this.$t('common.justNow');
            }
        }
    }
}
</script>

<style scoped>
img.loading {

    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    width: 60px !important;
    height: 60px !important;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0
    }

    100% {
        background-position: 800px 0
    }
}

.review-container {
    background: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.10);
    border-radius: 6px;
    padding: 15px;
    gap: 10px;

}

.review-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    width: calc(100% - 80px);
}

.user {
    border-radius: 15px;
}

.images {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.images img {
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.like {
    align-self: flex-start;
    color: rgba(var(--vs-primary)) !important;
}
</style><style>
.like .vs-button__content {
    padding: 0 !important;
}

.lg-backdrop {
    z-index: 9002;
}

.lg-outer.lg-slide {
    z-index: 9003;
}


</style>
