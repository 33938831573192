<template>
<vs-card >
    <template #title>
        <h3>{{ article != null ? article.title : '..' }}</h3>
    </template>
    <template #img>
        <img :src="picture_url" alt="">
    </template>
    <template #text>
        <p>
            {{ article != null ? (new Date(article.updated_at)).toLocaleDateString() : '..' }}
        </p>
    </template>
    <template #interactions v-if="article != null">
        <vs-button style="color: white!important;" shadow dark v-for="tag in article.tags" :key="'tag-' + tag">
            #{{ tag }}
        </vs-button>
    </template>
</vs-card>
</template>



<script>
import {
    s3Client
} from '../utils/S3Client';
export default {
    props: {
        article: Object,
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            picture_url: null
        }
    },

    async mounted(){
        // download picture from s3
        if(this.article != null && this.article.picture_id != null && this.article.picture_id != ''){
            const s3Result = await s3Client.getSignedDownloadURL({
                name: this.article.picture_id
            });

            if(s3Result && s3Result.url){
                this.picture_url = s3Result.url;
            }
        }
    }
}
</script>

<style>

</style>